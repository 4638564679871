import router from "./router";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { RouteLocationNormalized } from "vue-router";
import store from "@/store";

NProgress.configure({ showSpinner: false });

const whiteList = [
  "/login",
  "/app/personInfo",
  "/app/monitorplayWechat",
  "/app/videoWechat",
];

router.beforeEach(
  async (
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: any
  ) => {
    if (store.state.user.token) {
      if (to.path === "/login") {
        next({ path: "/" });
        NProgress.done();
      } else {
        next();
      }
    } else {
      if (whiteList.indexOf(to.path) !== -1) {
        next();
      } else {
        next(`/login?redirect=${to.path}`);
        NProgress.done();
      }
    }
  }
);

router.afterEach(() => {
  NProgress.done();
});
