import axios from "axios";
import store from "@/store";
import router from "@/router";
import { Toast } from "vant";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 15000,
});

// Request interceptors
service.interceptors.request.use(
  (config: any) => {
    try {
      const projectId = store.state.project.projectinfo.projectId;
      if (projectId !== null) {
        config.headers["pId"] = projectId;
      }
    } catch (e) {
      // to do sth
    }

    if (store.state.user.token && config.headers) {
      config.headers["token"] = store.state.user.token;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptors
service.interceptors.response.use(
  (response) => {
    if (response.data.code == 405) {
      // 未登陆码
      Toast({
        type: "fail",
        message: "用户未登录!",
      });
      store.commit("user/SET_TOKEN", "");
      router.push("/login");
    }
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
